import axios from "axios";
import { PlanData } from "../components/priceContainer/PlanData";

export const statusCheck = async (email) => {
  //#region  verfication of status subscrtion
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_MY_DOMAIN_SERVER}/status?email=${email}`
    );

    const resFindPlans = [];
    res.data.data.forEach((plans) => {
      if (PlanData.some((item) => item["path"] === plans.items.data[0].price.id)) {
        resFindPlans.push(plans.status);
      }
    });

    if (
      resFindPlans.includes("unpaid") ||
      resFindPlans.includes("canceled") ||
      resFindPlans.includes("incomplete") ||
      resFindPlans.includes("incomplete_expired") ||
      resFindPlans.includes("ended") ||
      resFindPlans.length === 0
    ) {
      return {
        status: false,
        message: `Abbonamento scaduto 
          effetturare il rinnovo 
          o contattare il gestore`,
      };
    } else {
      return {
        status: true,
        message: `abbonamento valido, OK`,
      };
    }
  } catch (error) {
    console.log(error.message);
    console.log(error.response.data);
    return {
      status: false,
      message: `${error.response.data}
          ${error.message}`,
    };
  }
  //#endregion
};
