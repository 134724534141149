import React from "react";
import HomeIcon from "@mui/icons-material/Home";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <HomeIcon />,
    cName: "nav-text",
  },
  {
    title: "Lista Crediti",
    path: "/lista-crediti",
    icon: <PeopleAltIcon />,
    cName: "nav-text",
  },
  {
    title: "Report",
    path: "/report",
    icon: <QueryStatsIcon />,
    cName: "nav-text",
  },
  {
    title: "Impostazioni Voci",
    path: "/voice-setting",
    icon: <SettingsSuggestIcon />,
    cName: "nav-text",
  },
  {
    title: "Help",
    path: "/help",
    icon: <LiveHelpIcon />,
    cName: "nav-text",
  },
  {
    title: "Contattaci",
    path: "/contactform",
    icon: <AttachEmailIcon />,
    cName: "nav-text",
  },
];
