export const DataTranform = (date) => {
   // la riporto nel formato vecchio
   date = date.toLocaleDateString().replaceAll("/", "-")

  // Split della data in giorno, mese e anno
  const [day, month, year] = date.split('-');
    
  // Rimozione degli zeri iniziali, se presenti, per giorno e mese
  const formattedDay = parseInt(day).toString();
  const formattedMonth = parseInt(month).toString();
  
  // Composizione della nuova stringa di data nel formato desiderato
  const localeDateString = `${formattedDay}-${formattedMonth}-${year}`;
  
  return localeDateString;
}