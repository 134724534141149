import "../styles/Price.css";
import "../styles/Price.scss";
import { PlanData } from "../components/priceContainer/PlanData";
import PlanCard from "../components/priceContainer/PlanCard";

const Auth = () => {
  const renderPlanCard = () => {
    return PlanData.map((item) => {
      return <PlanCard item={item} />;
    });
  };

  return (
    <div className="local-bootstrap">
      <div className="price-container">
        {/* {shouldRedirect} */}
        <div className="price-head">
          <h1 class="display-1 bold-price-fm">Plans Pricing</h1>
          {/* <p className="price-t"></p> */}
          <h1 className="display-6 text-price-fm">30 giorni di prova gratuita</h1>
        </div>
        <div className="price-body">{renderPlanCard()}</div>
      </div>
    </div>
  );
};
export default Auth;
