import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { CeckConnection } from "../functions/ConnectionStatius";
import Swal from "sweetalert2";
import "../styles/Report.css";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { errorReset } from "../store/actions/handleHome";
import { fetchReport } from "../store/actions/handelReport";
import Spinner from "../components/Spinner";
import { StyleReportTotColorObject } from "../functions/StyleReportTotColorObject";
import { stateChange } from "../functions/stateChange";
import * as VscIcons from "react-icons/vsc";
import * as BiIcons from "react-icons/bi";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import DatePicker from "react-datepicker";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Chart from "react-apexcharts";

import "../styles/Price.scss";
import CardSingleStats from "../components/reportComponent/CardSingleStats";
import CardSingleStatsChart from "../components/reportComponent/CardSingleStatsChart";
function Report() {
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const userToken = useSelector((state) => state.authReducer.token);
  // const startDate = useSelector((state) => state.dataReducer.startDate);

  const {
    loading,
    arrayReport,
    arrVociCassa,
    arrTotVociCassa,
    totNetto,
    dataInizioStatistiche,
    dataFineStatistiche,
    option,
    option_spese,
  } = useSelector((state) => state.reportReducer);

  const [inputDal, setInputDal] = useState(new Date());
  const [inputAl, setInputAl] = useState(new Date());
  const styleTotNetto = StyleReportTotColorObject(totNetto);

  useEffect(() => {
    // verifica connessione
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }

    //______

    if (token) {
      stateChange();
      dispatch(errorReset());

      dispatch(fetchReport(dateFormater(inputDal), dateFormater(inputAl)));
    }
    // eslint-disable-next-line
  }, [dispatch, token]);

  // se non è presente il token allora il cliente  non è registrato oppure non è loggato
  if (!token) {
    return <Redirect to="/auth"></Redirect>;
  }
  // const renderHeadReport = () => {
  //   return arrVociCassa.map((voce) => {
  //     return <CardHeadReport nome={voce.nome} />;
  //   });
  // };
  // const renderBodyReport = () => {
  //   return arrayReport.map((item) => {
  //     return <CardBodyReport data={item.data} arrayValori={item.arrayDay.arrayClean} />;
  //   });
  // };

  const renderFooterReport = () => {
    const listDate = arrayReport.map((item) => item.data);
    return arrVociCassa
      .filter((x) => x.alias !== "aggio-giornaliero" && x.alias !== "spese")
      .map((voce, index) => {
        const arrVoce = arrayReport.map((item) => {
          return item.arrayDay.arrayClean.filter((x) => x.alias === voce.alias);
        });
        const options = {
          series: [
            {
              name: "Giorno",
              data: arrVoce.map((item) => Number(item[0].valore)),
            },
          ],
          options: {
            chart: {
              height: 350,
              type: "line",
              zoom: {
                enabled: false,
              },
            },
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "straight",
            },

            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            xaxis: {
              categories: listDate,
              labels: {
                show: false,
              },
            },
          },
        };

        return (
          <CardSingleStatsChart
            name={voce.nome}
            option={options}
            percent={voce.percentualeQuadagno}
          />
        );
      });
  };

  const handleCerca = (date1, date2) => {
    dispatch(fetchReport(dateFormater(date2), dateFormater(date1)));
  };

  const dateFormater = (date) => {
    return date.toLocaleDateString().replaceAll("/", "-");
  };

  const totGuadagnoLordo = () => {
    if (arrTotVociCassa.length === 0) return 0;
    const arrTot = arrTotVociCassa.map((item) => Number(item.totVoce));
    const tot = arrTot.reduce((a, b) => a + b, 0);
    return tot.toFixed(2);
  };

  const handleDateSelect = async (date, type) => {
    if (!CeckConnection()) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Sembra che la tua connessione sia assente o instabile",
      });
      return;
    }
    //-----

    if (type) {
      setInputAl(date);
    } else {
      setInputDal(date);
    }
  };

  return (
    <>
      {userToken ? <Navbar isAutenticated={userToken} /> : null}
      <div className="report">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <div className="data-stats">
              <div className="local-bootstrap row_div">
                <div
                  class="input-group input-group-sm me-1 w-30 ipt-report align-items-center row_date"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    marginLeft: "20px",
                  }}
                >
                  <p>Dal</p>
                  <DatePicker
                    // className="data-pikers-date"
                    locale="it"
                    selected={inputDal}
                    value={inputDal}
                    onChange={(date) => handleDateSelect(date, false)}
                    dateFormat="dd-MM-yyyy"
                    sx={{ zIndex: "100" }}
                    className="input_date"
                    maxDate={inputAl}
                    // calendarClassName="data-calendar"
                  />
                  <CalendarMonthIcon
                    sx={{ color: "#071358", fontSize: "30px" }}
                    style={{ marginLeft: "3px" }}
                  />
                </div>

                <div
                  class="input-group input-group-sm me-1 w-30 ipt-report align-items-center row_date"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "nowrap",
                    marginLeft: "20px",
                  }}
                >
                  <p>Al</p>

                  <DatePicker
                    // className="data-pikers-date"
                    locale="it"
                    selected={inputAl}
                    value={inputAl}
                    onChange={(date) => handleDateSelect(date, true)}
                    dateFormat="dd-MM-yyyy"
                    sx={{ position: "absolute", top: "100", left: "0", zIndex: "100" }}
                    className="input_date"
                    maxDate={new Date()}
                    // calendarClassName="data-calendar"
                  />

                  <CalendarMonthIcon
                    sx={{ color: "#071358", fontSize: "30px" }}
                    style={{ marginLeft: "3px" }}
                  />
                </div>
              </div>

              <div className="pdf-div" onClick={() => handleCerca(inputDal, inputAl)}>
                <BiIcons.BiSearchAlt className="ico-pdf-fm" />
                Cerca
              </div>
              
              <Link
                className="link-pdf"
                data-tip={
                  "Premendo questo tasto <br /> verrà creato un file pdf <br /> del report nelle date selezionate "
                }
                data-delay-show="300"
                to={{
                  pathname: `/report/pdf`,
                  state: {
                    arrayReport,
                    arrVociCassa,
                    arrTotVociCassa,
                    dataInizioStatistiche,
                    dataFineStatistiche,
                  },
                }}
              >
                <div className="pdf-div">
                  <VscIcons.VscFilePdf className="ico-pdf-fm" />
                  Esporta
                </div>
              </Link>
            </div>
            <div className="stats_container">
              {arrVociCassa
                .filter((x) => x.alias !== "aggio-giornaliero" && x.alias !== "spese")
                .map((voce, index) => {
                  const styleTot = StyleReportTotColorObject(
                    arrTotVociCassa.filter(
                      (x) => x.alias !== "aggio-giornaliero" && x.alias !== "spese"
                    )[index].totVoce
                  );

                  return (
                    
                    <CardSingleStats
                      voce={voce}
                      tot={
                        arrTotVociCassa.filter(
                          (x) => x.alias !== "aggio-giornaliero" && x.alias !== "spese"
                        )[index]
                      }
                      style={styleTot}
                    />
                  );
                })}
            </div>
            <div className="stats_container_totali">
              <div className="contaienr_totale">
                <p className="head_tot">Report Statistiche</p>
                <div className="row_body_stats">
                  <div className="col_data">
                    <div className="row_data">
                      <p>Totale Incasso Lordo:</p>
                      <p style={styleTotNetto}>{totGuadagnoLordo()}€</p>
                    </div>
                    <div className="row_data">
                      <p>Totale Spese:</p>
                      <p style={{ color: "red" }}>
                        {arrTotVociCassa[0] ? arrTotVociCassa[0].totVoce : 0}€
                      </p>
                    </div>
                    <div className="row_data">
                      <p>Guadango Netto:</p>
                      <p style={styleTotNetto}>{Number(totNetto).toFixed(2)}€</p>
                    </div>
                    <Chart
                      options={option_spese.options}
                      series={option_spese.series}
                      type="bar"
                      height={200}
                      width={500}
                    />
                  </div>
                  <div className="col_data" style={{ marginTop: "-50px" }}>
                    {option.series.reduce((a, b) => a + b, 0) === 0 ? (
                      <p>Non ci sono dati sufficenti per mostrare il grafico</p>
                    ) : (
                      <Chart
                        options={option.options}
                        series={option.series}
                        type="pie"
                        width={500}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="contaienr_stats_detail">{renderFooterReport()}</div>
          </>
        )}
      </div>
    </>
  );
}

export default Report;
